export function sync(promise) {
    const sync = {
        sync: undefined,
        promise: promise.then(v => {
            sync.sync = v;
            return v;
        }),
    };
    return sync;
}
// Call an async function with a maximum time limit (in milliseconds) for the timeout
export async function promiseTimeout(asyncPromise, timeLimit) {
    let timeoutHandle = undefined;
    const timeoutPromise = new Promise((_, reject) => {
        timeoutHandle = setTimeout(() => reject(new Error('Async call timeout limit reached')), timeLimit);
    });
    const result = await Promise.race([asyncPromise, timeoutPromise]);
    if (timeoutHandle)
        clearTimeout(timeoutHandle);
    return result;
}
